import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { PV_USER_SERVICE } from '@pv-frontend/pv-shared-services/pv-user-service';
import * as Sentry from '@sentry/angular';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { environment } from '../environments/environment';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
import { TokenInterceptor } from '../interceptors/token.interceptor';
import { PvUserServiceImpl } from '../user-service-impl.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: PV_USER_SERVICE,
      useExisting: PvUserServiceImpl,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: 'env',
      useValue: environment,
    },
    MessageService,
    PvUserServiceImpl,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
